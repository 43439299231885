import {useEffect, useState} from 'react'
import {uniq} from 'lodash'
import {useFullSearch} from 'api'

export const useSearch = () => {
  const [filterTypes, _setFilterTypes] = useState<string[]>([])
  const [filterText, setFilterText] = useState('')
  const [includeInactive, setIncludeInactive] = useState(false)
  const [queryParams, setQueryParams] = useState({
    filterTypes,
    filterText,
    includeInactive,
  })

  useEffect(() => {
    const timeout = setTimeout(
      () =>
        setQueryParams({
          filterTypes,
          filterText,
          includeInactive,
        }),
      250
    )
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [filterTypes, filterText, includeInactive])

  const {data, fetchStatus} = useFullSearch(queryParams)
  const results = data?.results ?? []

  const setFilterType = (filterType: string) =>
    _setFilterTypes((values) => uniq([...values, filterType]))

  const removeFilterType = (filterType: string) =>
    _setFilterTypes((values) => {
      const itemIndex = values.indexOf(filterType)
      return [...values.slice(0, itemIndex), ...values.slice(itemIndex + 1)]
    })

  const toggleFilterType = (filterType: string) =>
    _setFilterTypes((values) => {
      if (!values.includes(filterType)) {
        return uniq([...values, filterType])
      } else {
        const itemIndex = values.indexOf(filterType)
        return [...values.slice(0, itemIndex), ...values.slice(itemIndex + 1)]
      }
    })

  const clearFilter = () => {
    _setFilterTypes([])
    setFilterText('')
  }

  return {
    results: results ?? [],
    isFetching: fetchStatus === 'fetching',
    filterTypes,
    setFilterType,
    removeFilterType,
    toggleFilterType,
    filterText,
    setFilterText,
    includeInactive,
    setIncludeInactive,
    clearFilter,
  }
}
