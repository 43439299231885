import {Fragment} from 'react'
import {cn} from 'utils'
import type {
  GroupedListGroupProps,
  GroupedListGroupsProps,
  GroupedListItemsProps,
  GroupedListProps,
  GroupedListContainerProps,
} from './types'

/*** NEW COMPONENTS ***/

function GroupedListContainer<I>({
  isGrouped,
  className,
  children,
}: GroupedListContainerProps<I>) {
  return (
    <div className={cn('overflow-y-auto', isGrouped && 'space-y-3', className)}>
      {children}
    </div>
  )
}

function GroupedListItems<I>({
  items,
  itemRenderer,
  itemKey = (item, index) => index,
  listStyle,
  noItemsText = 'No items',
}: GroupedListItemsProps<I>) {
  return (
    <div
      className={cn(
        listStyle === 'grid' &&
          'grid grid-cols-[repeat(auto-fill,_minmax(240px,1fr))] gap-3 px-3 py-3'
      )}
    >
      {!items.length && <div>{noItemsText}</div>}
      {items.map((item, index) => (
        <Fragment key={itemKey(item, index)}>
          {itemRenderer({data: item})}
        </Fragment>
      ))}
    </div>
  )
}

function GroupedListGroup<I, G>({
  group,
  groupHeaderRenderer,
  groupRenderEmpty,
  items,
  ...props
}: GroupedListGroupProps<I, G>) {
  if (!groupRenderEmpty && !items.length) {
    return null
  }

  return (
    <div>
      {groupHeaderRenderer({group})}
      <GroupedListItems {...props} items={items} />
    </div>
  )
}

function GroupedListGroups<I, G>({
  groups,
  groupKey,
  groupBy,
  items,
  ...props
}: GroupedListGroupsProps<I, G>) {
  return (
    <>
      {groups.map((group, idx) => {
        return (
          <GroupedListGroup
            {...props}
            key={groupKey(group, idx)}
            group={group}
            items={items.filter((item) => groupBy(group, item))}
          />
        )
      })}
    </>
  )
}

export const GroupedList = <I, G>({
  noResultsRenderer,
  className,
  ...props
}: GroupedListProps<I, G>) => {
  if (noResultsRenderer && !props.items.length) {
    return (
      <GroupedListContainer className={className} isGrouped={props.isGrouped}>
        {noResultsRenderer()}
      </GroupedListContainer>
    )
  }

  if (props.isGrouped) {
    return (
      <GroupedListContainer className={className} isGrouped={props.isGrouped}>
        <GroupedListGroups {...props} />
      </GroupedListContainer>
    )
  }

  return (
    <GroupedListContainer className={className} isGrouped={props.isGrouped}>
      <GroupedListItems {...props} />
    </GroupedListContainer>
  )
}
