import {useState} from 'react'
import {useSearch} from 'app/modal/Search/hooks'
import {
  Button,
  ButtonSelect,
  EntityCard,
  GroupedList,
  Icon,
  NoResults,
  Search,
  Spinner,
  Visible,
  Toggle,
  CloseButton,
  ButtonGroup,
} from 'components/elements'
import {
  Container,
  Header,
  HeaderLeft,
  HeaderRight,
  Filters,
  Main,
} from 'containers/Core'
import {useEntity} from 'hooks/useEntity'
import type {DepartmentTypeOption} from 'options/departmentTypes'
import {DEPARTMENT_TYPE_OPTIONS} from 'options/departmentTypes'
import type {DepartmentType} from 'types/department'
import {DEPARTMENT_TYPE} from 'types/department'
import type {SearchResult} from 'types/searchResult'

const messages = {
  title: 'List of business cards',
  newCard: 'Create new card',
  isFetching: 'Loading...',
  emptyList: 'No business cards',
  noSearchResults: 'No business cards found',
  clearFiltersBtnText: 'clear',
}

const ACTIVE_TYPES: DepartmentType[] = ['PERSON', 'ACT', 'ORGANIZER', 'VENUE']

interface SearchViewProps {
  onCloseClick: () => void
}

type SearchViewShowKind = 'card' | 'list'

interface SearchViewListProps {
  results: SearchResult[]
  isFetching: boolean
  showKind: SearchViewShowKind
  grouped: boolean
  hasFilterText: boolean
}

const SearchResultGroupHeader = ({icon, namePlural}: DepartmentTypeOption) => (
  <header className='bg-oxfordBlue text-santasGray flex items-center px-5 sticky z-[100] h-9'>
    <Icon className='top-0' icon={icon} size='18' />
    <span className='ml-2.5'>{namePlural}</span>
  </header>
)

const SearchViewList = ({
  results,
  isFetching,
  showKind,
  grouped,
  hasFilterText,
}: SearchViewListProps) => {
  const {openEntity} = useEntity()

  if (isFetching) {
    return null
  }

  const groups = Object.keys(DEPARTMENT_TYPE_OPTIONS).map((key) => ({
    key,
    ...DEPARTMENT_TYPE_OPTIONS[key],
  }))

  return (
    <GroupedList
      groups={groups}
      isGrouped={grouped}
      items={results}
      groupHeaderRenderer={({group}) => (
        <SearchResultGroupHeader
          name={group.name}
          namePlural={group.namePlural}
          icon={group.icon}
        />
      )}
      itemKey={(item) => `${item.type}__${item.id}`}
      groupKey={(group) => group.key}
      groupBy={(group, item) => item.type === group.key}
      listStyle={showKind === 'list' ? 'list' : 'grid'}
      itemRenderer={({data}) => (
        <EntityCard
          data={data}
          cardType={showKind}
          entityType={(item) => ('type' in item ? item.type : 'ACT')}
          onClick={({id, type}) => {
            if (
              type === 'BOOKING_AGENCY' ||
              type === 'ACT' ||
              type === 'ORGANIZER' ||
              type === 'VENUE' ||
              type === 'TOUR' ||
              type === 'PERSON'
            ) {
              openEntity(id, type)
            }
          }}
        />
      )}
      noResultsRenderer={() => (
        <>
          <NoResults
            test={() => !results.length && !hasFilterText}
            className={
              'h-full grid place-items-center text-lionSize text-secondaryTextColor'
            }
          >
            <div className='grid place-items-center'>
              <Icon icon='search-results' size='96' />
              <div>Type above to search</div>
            </div>
          </NoResults>
          <Visible ifFalse={isFetching}>
            <NoResults
              test={() => !results.length && hasFilterText}
              className={
                'h-full grid place-items-center text-lionSize text-secondaryTextColor'
              }
            >
              <div>No search results found</div>
            </NoResults>
          </Visible>
        </>
      )}
    />
  )
}

export const SearchView = ({onCloseClick}: SearchViewProps) => {
  const [showKind, setShowKind] = useState<SearchViewShowKind>('card')
  const [grouped, setGrouped] = useState(false)

  const {
    results,
    isFetching,
    filterTypes,
    toggleFilterType,
    filterText,
    setFilterText,
    includeInactive,
    setIncludeInactive,
    clearFilter,
  } = useSearch()

  const handleSearch = (searchText: string) => setFilterText(searchText)

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <Search
            className='flex-1'
            placeholder='Search here'
            value={filterText}
            isFetching={isFetching}
            onChange={handleSearch}
            theme='bigSearch'
            keepClearButton
            autoFocus
          />
        </HeaderLeft>
        <HeaderRight>
          <CloseButton onClick={onCloseClick} />
        </HeaderRight>
      </Header>

      <Filters className='flex flex-row flex-nowrap items-center justify-between'>
        <section className='flex items-center'>
          <ButtonGroup>
            {DEPARTMENT_TYPE.map((departmentType) => {
              if (!ACTIVE_TYPES.includes(departmentType)) {
                return null
              }

              const filter = DEPARTMENT_TYPE_OPTIONS[departmentType]

              if (!filter) {
                return null
              }

              return (
                <Button
                  key={departmentType}
                  icon={filter.icon}
                  label={filter.namePlural}
                  isSelected={filterTypes.includes(departmentType)}
                  onClick={() => toggleFilterType(departmentType)}
                />
              )
            })}
          </ButtonGroup>

          <Button
            onClick={clearFilter}
            label={messages.clearFiltersBtnText}
            disabled={!filterTypes.length}
            theme='text'
          />
        </section>

        <section className='flex gap-x-2.5'>
          <Toggle
            value={grouped}
            onChange={setGrouped}
            inactiveText='ungrouped'
            activeText='grouped'
            size='small'
          />
          <Toggle
            value={includeInactive}
            onChange={setIncludeInactive}
            inactiveText='Active'
            activeText='All'
            invert
            size='small'
          />
        </section>

        <ButtonSelect<SearchViewShowKind>
          value={showKind}
          onChange={(value) => setShowKind(value)}
          options={[
            {value: 'card', icon: 'tiles'},
            {value: 'list', icon: 'list'},
          ]}
        />
      </Filters>

      <Main grid>
        <Visible ifTrue={isFetching}>
          <Spinner />
        </Visible>

        <SearchViewList
          results={results}
          isFetching={isFetching}
          grouped={grouped}
          showKind={showKind}
          hasFilterText={filterText.length >= 2}
        />
      </Main>
    </Container>
  )
}
