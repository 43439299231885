import {useAuth} from './useAuth'

interface Config {
  digisign: boolean
}

export const useConfig = (): Config => {
  const auth = useAuth()

  const digisign = !!auth.token.organization_settings?.find(
    (setting) =>
      setting.name === 'FEATURE_FLAG_DISABLE_SIGNING' && setting.value === false
  )

  return {
    digisign: digisign,
  }
}
