import {Icon, Tooltip} from 'components/elements'
import {phoneNumber, dateFromStartOnAndStartAt} from 'helpers/formatters'
import {
  getOrganizationIconFromType,
  getOrganizationNameFromType,
} from 'helpers/organization'
import type {PersonBusinessCard} from 'types/businessCard'
import type {
  EntityCardData,
  EntityCardOptions,
  EntityCardTypeActive,
} from 'types/entityCard'
import {DEPARTMENT_TYPE_OPTIONS} from './departmentTypes'

const getName = (d: EntityCardData) => {
  if ('name' in d) {
    return d.name
  }
}

const getFullName = (d: EntityCardData) => {
  if ('full_name' in d) {
    return d.full_name
  }
}

const getOrganizationName = (d: EntityCardData) => {
  if ('organization_name' in d) {
    return d.organization_name
  }
}

const getPersonName = (d: EntityCardData) => {
  if (getName(d)) {
    return getName(d)
  }
  if ('business_card' in d) {
    const {given_name, family_name} = d.business_card as PersonBusinessCard
    return `${given_name} ${family_name}`
  }
}

const getLocked = (d: EntityCardData) => {
  if ('is_public' in d) {
    return !d.is_public
  }
  return false
}

const getIsRepresentedByMe = (d: EntityCardData) => {
  if ('is_represented_by_me' in d) {
    return !!d.is_represented_by_me
  }
  return false
}

const getAffiliationPersonName = (d: EntityCardData) => {
  if ('affiliation' in d) {
    const {person} = d.affiliation
    return person.full_name
  }
}

const getEmail = (d: EntityCardData) => {
  let emails
  if ('emails' in d) {
    emails = d.emails
  } else if ('business_card' in d) {
    emails = d.business_card.email_addresses
  }

  if (emails && emails.length > 0) {
    const email = emails[0]
    if (email) {
      return typeof email === 'string' ? email : email.value
    }
  }
}

const getPhone = (d: EntityCardData) => {
  let phones
  if ('phone_numbers' in d) {
    phones = d.phone_numbers
  } else if ('business_card' in d) {
    phones = d.business_card.phone_numbers
  }

  if (!!phones && phones.length) {
    return phoneNumber(phones[0]) ?? undefined
  }
}

const getLockedTooltip = (d: EntityCardData) => {
  if ('name' in d) {
    return `${d.name} is only visible to you and your colleagues`
  }
}

const getActName = (d: EntityCardData) => {
  if ('act' in d) {
    return d.act?.name
  }
}

const getVenueName = (d: EntityCardData) => {
  if ('venue' in d) {
    return d.venue?.name
  }
}

const getDateFromStartOnAndStartAt = (d: EntityCardData) => {
  if ('start_on' in d) {
    return dateFromStartOnAndStartAt(d)
  }
}

const productionEventRibbonVisible = (d: EntityCardData) => {
  if ('production_status' in d) {
    if (d.status === 'CONTRACT_STATUS_TBC') {
      return true
    } else if (d.production_status === 'PRODUCTION_STATUS_READY') {
      return d.daysLeft <= 14
    } else if (d.production_status === 'PRODUCTION_STATUS_IN_PROGRESS') {
      return d.daysLeft <= 2
    } else {
      return false
    }
  }
  return false
}

const productionEventRibbonText = (d: EntityCardData) => {
  if ('production_status' in d) {
    if (d.status === 'CONTRACT_STATUS_TBC') {
      return 'TBC'
    } else {
      return `${d.daysLeft} day${d.daysLeft === 1 ? 's' : ''} left`
    }
  }
}

const productionEventRibbonStyle = (d: EntityCardData) => {
  if ('production_status' in d) {
    if (d.status === 'CONTRACT_STATUS_TBC') {
      return 'orange'
    }
  }
  return 'amaranth'
}

const bookingEventRibbonVisible = (d: EntityCardData) => {
  if ('status' in d) {
    if (d.status === 'CONTRACT_STATUS_TBC') {
      return d.daysLeft <= 14
    } else if (d.status === 'CONTRACT_STATUS_CONFIRMED') {
      return d.daysLeft <= 14
    } else if (d.status === 'CONTRACT_STATUS_FINALIZED') {
      return d.daysLeft <= 2
    } else {
      return false
    }
  }
  return false
}

const bookingEventRibbonText = (d: EntityCardData) => {
  if ('status' in d) {
    return `${d.daysLeft} day${d.daysLeft === 1 ? 's' : ''} left`
  }
}

const roleTypeConfig: EntityCardOptions = {
  icon: {
    default: DEPARTMENT_TYPE_OPTIONS.PERSON.icon,
  },
  lines: {
    primary: {text: getAffiliationPersonName},
  },
}

export const ENTITY_CARD_OPTIONS: Record<
  EntityCardTypeActive,
  EntityCardOptions
> = {
  PERSON: {
    icon: {default: DEPARTMENT_TYPE_OPTIONS.PERSON.icon},
    lines: {
      primary: {text: getPersonName},
      secondary: {text: getEmail},
      tertiary: {text: getPhone},
    },
    content: ({data, className}) => {
      if (!('affiliated_to' in data)) {
        return null
      }
      if (!data.affiliated_to) {
        return null
      }

      return (
        <ul className={className}>
          {data.affiliated_to.map((item) => (
            <li key={item.id}>
              <Tooltip
                content={getOrganizationNameFromType(item.type)}
                position='left'
                showDelay={0}
              >
                <div className='flex gap-x-1 items-center'>
                  {!!getOrganizationIconFromType(item.type) && (
                    <Icon
                      icon={getOrganizationIconFromType(item.type)}
                      className='flex-none'
                    />
                  )}
                  {item.name}
                </div>
              </Tooltip>
            </li>
          ))}
        </ul>
      )
    },
    corners: {
      left: {
        visible: getLocked,
        tooltip: getLockedTooltip,
        icon: 'lock',
        style: 'orange',
      },
    },
  },
  LIST_PERSON: {
    icon: {default: DEPARTMENT_TYPE_OPTIONS.PERSON.icon},
    lines: {
      primary: {text: getFullName},
    },
  },
  ACT: {
    icon: {default: DEPARTMENT_TYPE_OPTIONS.ACT.icon},
    lines: {
      primary: {text: getName},
      secondary: {text: getEmail},
      tertiary: {text: getPhone},
    },
    ribbons: {
      right: {
        visible: getIsRepresentedByMe,
        text: 'Your act',
        style: 'blue',
      },
    },
    corners: {
      left: {
        visible: getLocked,
        tooltip: getLockedTooltip,
        icon: 'lock',
        style: 'orange',
      },
      right: {
        visible: (d: EntityCardData) => {
          if ('is_active' in d) {
            return !d.is_active
          }
          return false
        },
        tooltip: () => 'Act is deactivated',
        icon: 'palm',
        style: 'blue',
      },
    },
  },
  ORGANIZER: {
    icon: {default: DEPARTMENT_TYPE_OPTIONS.ORGANIZER.icon},
    lines: {
      primary: {text: getName},
      secondary: {text: getEmail},
      tertiary: {text: getPhone},
    },
    corners: {
      left: {
        visible: getLocked,
        tooltip: getLockedTooltip,
        icon: 'lock',
        style: 'orange',
      },
    },
  },
  BOOKING_AGENCY: {
    icon: {
      default: DEPARTMENT_TYPE_OPTIONS.BOOKING_AGENCY.icon,
    },
    lines: {
      primary: {text: getName},
      secondary: {text: getEmail},
      tertiary: {text: getPhone},
    },
    corners: {
      left: {
        visible: getLocked,
        tooltip: getLockedTooltip,
        icon: 'lock',
        style: 'orange',
      },
    },
  },
  VENUE: {
    icon: {default: DEPARTMENT_TYPE_OPTIONS.VENUE.icon},
    lines: {
      primary: {text: getName},
      secondary: {text: getOrganizationName},
      tertiary: {text: getPhone},
    },
    corners: {
      left: {
        visible: getLocked,
        tooltip: getLockedTooltip,
        icon: 'lock',
        style: 'orange',
      },
    },
  },

  ACT_ADMIN: roleTypeConfig,
  ACT_MEMBER: roleTypeConfig,
  ACT_BOOKING_AGENCY_BOOKER: roleTypeConfig,
  BOOKING_AGENCY_ADMIN: roleTypeConfig,
  BOOKING_AGENCY_BOOKER: roleTypeConfig,
  BOOKING_AGENCY_ACCOUNTANT: roleTypeConfig,
  ORGANIZER_ADMIN: roleTypeConfig,
  ORGANIZER_STAFF: roleTypeConfig,
  TOUR_MANAGEMENT_ADMIN: roleTypeConfig,
  TOUR_MANAGEMENT_STAFF: roleTypeConfig,
  VENUE_ADMIN: roleTypeConfig,
  VENUE_STAFF: roleTypeConfig,

  PRODUCTION_EVENT: {
    icon: {default: 'microphone'},
    lines: {
      primary: {text: getActName},
      secondary: {text: getVenueName},
      tertiary: {text: getDateFromStartOnAndStartAt},
    },
    ribbons: {
      left: {
        visible: productionEventRibbonVisible,
        text: productionEventRibbonText,
        style: productionEventRibbonStyle,
      },
    },
  },

  BOOKING_STATUS_EVENT: {
    icon: {default: 'microphone'},
    lines: {
      primary: {text: getActName},
      secondary: {text: getVenueName},
      tertiary: {text: getDateFromStartOnAndStartAt},
    },
    ribbons: {
      left: {
        visible: bookingEventRibbonVisible,
        text: bookingEventRibbonText,
        style: 'amaranth',
      },
    },
  },
}
