import type {IconName} from 'components/elements'
import type {OrganizationType} from 'types/organization'

export function getOrganizationNameFromType(type: OrganizationType): string {
  switch (type) {
    case 'ORGANIZATION_TYPE_UNDECIDED':
      return 'Unknown'
    case 'ORGANIZATION_TYPE_BOOKING_AGENCY':
      return 'Booking Agency'
    case 'ORGANIZATION_TYPE_ORGANIZING_VENUE':
      return 'Venue'
    case 'ORGANIZATION_TYPE_ORGANIZER':
      return 'Organizer'
    case 'ORGANIZATION_TYPE_VENUE':
      return 'Venue'
    case 'ORGANIZATION_TYPE_ACT':
      return 'Act'
    case 'ORGANIZATION_TYPE_AUTONOMOUS_ACT':
      return 'Act'
  }
}

export function getOrganizationIconFromType(type: OrganizationType): IconName {
  switch (type) {
    case 'ORGANIZATION_TYPE_UNDECIDED':
      return 'more'
    case 'ORGANIZATION_TYPE_BOOKING_AGENCY':
      return 'pencil'
    case 'ORGANIZATION_TYPE_ORGANIZING_VENUE':
    case 'ORGANIZATION_TYPE_ORGANIZER':
      return 'phone'
    case 'ORGANIZATION_TYPE_VENUE':
      return 'speaker'
    case 'ORGANIZATION_TYPE_ACT':
    case 'ORGANIZATION_TYPE_AUTONOMOUS_ACT':
      return 'microphone'
  }
}
