import {getTime} from 'date-fns'
import jwtDecode from 'jwt-decode'
import {api} from 'api/api'
import {AUTH_RENEW_AUTORENEW_DELTA} from 'options/auth'

export interface JwtToken {
  token: string
  username: string
  user_id: number
  exp: number
  email: string
  orig_iat: number
  jwt_expiration_delta: string
  organization_id: string
  organization_settings: {
    is_read_only: boolean
    name: string
    value: boolean | string | string[]
  }[]
}

export function getToken() {
  return localStorage.getItem('token')
}

export function setToken(token: string) {
  return localStorage.setItem('token', token)
}

export function clearToken() {
  return localStorage.removeItem('token')
}

export function decodeToken(token: string): JwtToken {
  return {
    token,
    ...jwtDecode<Omit<JwtToken, 'token'>>(token),
  }
}

export async function getRefreshToken(token: string) {
  return api.auth.refreshJwtToken(token)
}

export async function validateToken(): Promise<string | null> {
  try {
    const token = getToken()

    if (!token) {
      throw new Error('No token found')
    }

    let decodedToken: JwtToken | undefined
    try {
      decodedToken = decodeToken(token)
    } catch (e) {
      throw new Error('Invalid token found')
    }
    if (!decodedToken || !decodedToken.exp) {
      throw new Error('Invalid token found')
    }

    const expires = decodedToken.exp
    const now = Math.floor(getTime(new Date()) / 1000)

    // Token is completely expired
    if (expires - now < 0) {
      throw new Error('Token expired')
    }

    // Token is up for renewal
    if (expires - (now + AUTH_RENEW_AUTORENEW_DELTA) < 0) {
      try {
        const resp = await getRefreshToken(token)
        setToken(resp.token)
        return resp.token
      } catch {
        throw new Error('Could not refresh token')
      }
    }

    return token
  } catch (e) {
    clearToken()
    return null
  }
}

export async function validateAndDecodeToken(): Promise<JwtToken | null> {
  try {
    const token = await validateToken()
    if (!token) {
      return null
    }
    return decodeToken(token)
  } catch {
    return null
  }
}
